import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';

// To be injected at Client Side Render time
@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    console.error(e);
    if (window.location.hostname === 'joshsfrogs.com' || window.location.hostname === 'new.joshsfrogs.com') {
      // @ts-ignore
      /*
      window.rg4js('send', {
        error: e
      });
      */
    }
  }
}

// To be injected at Server Side Render time
@Injectable()
export class FakeRaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    console.error(e);
  }
}
